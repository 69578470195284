<template>
    <v-dialog v-if="user" :value="dialog" persistent width="800px">
        <v-card class="pa-5">
            <v-form ref="form" lazy-validation>
                <v-row class="justify-center">
                  <v-col cols="6">
                    <v-select
                      v-if="incidenceSelected"
                      placeholder="Tipo"
                      :items="tipoItems"
                      ref="tipo"
                      :value="incidenceSelected.type"
                      :rules="reglaRequerido"
                    ></v-select>
                    <v-select
                      v-else
                      placeholder="Tipo"
                      :items="tipoItems"
                      v-model="tipo"
                      :rules="reglaRequerido"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-if="incidenceSelected"
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaFormateada"
                          label="Fecha"
                          ref="fecha"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="reglaRequerido"
                          @blur="fecha = parseDate(fechaFormateada)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fecha"
                        locale="es-ES"
                        :first-day-of-week="1"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      v-else
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaFormateada"
                          label="Fecha"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :rules="reglaRequerido"
                          @blur="fecha = parseDate(fechaFormateada)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fecha"
                        locale="es-ES"
                        :first-day-of-week="1"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :value="incidenceSelected.location"
                      v-if="incidenceSelected"
                      :items="ubicacionItems"
                      ref="ubicacion"
                      placeholder="Ubicación"
                    ></v-select>
                    <v-select
                      v-else
                      placeholder="Ubicación"
                      v-model="ubicacion"
                      :items="ubicacionItems"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :value="incidenceSelected.road"
                      v-if="incidenceSelected"
                      :items="carreteraItems"
                      placeholder="Carretera"
                      ref="road"
                    ></v-select>
                    <v-select
                      v-else
                      :items="carreteraItems"
                      placeholder="Carretera"
                      v-model="road"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      :value="incidenceSelected.pk"
                      v-if="incidenceSelected"
                      placeholder="PK"
                      ref="pk"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      placeholder="PK"
                      v-model="pk"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :value="incidenceSelected.reason"
                      v-if="incidenceSelected"
                      placeholder="Causa"
                      :items="causaItems"
                      ref="causa"
                    ></v-select>
                    <v-select
                      v-else
                      placeholder="Causa"
                      :items="causaItems"
                      v-model="causa"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :value="incidenceSelected.external_actors"
                      v-if="incidenceSelected"
                      placeholder="Actores externos"
                      :items="actoresExternosItems"
                      ref="actoresExternos"
                      multiple
                    ></v-select>
                    <v-select
                      v-else
                      multiple
                      placeholder="Actores externos"
                      :items="actoresExternosItems"
                      v-model="actoresExternos"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      :value="incidenceSelected.external_damage"
                      v-if="incidenceSelected"
                      solo
                      placeholder="Daños"
                      ref="daños"
                    ></v-textarea>
                    <v-textarea
                      v-else
                      solo
                      placeholder="Daños"
                      v-model="daños"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :value="incidenceSelected.internal_damage"
                      v-if="incidenceSelected"
                      placeholder="Afecciones"
                      :items="afeccionesItems"
                      ref="afecciones"
                      multiple
                    ></v-select>
                    <v-select
                      v-else
                      multiple
                      placeholder="Afecciones"
                      :items="afeccionesItems"
                      v-model="afecciones"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      :value="incidenceSelected.resolution"
                      v-if="incidenceSelected"
                      solo
                      placeholder="Resolución"
                      ref="resolucion"
                    ></v-textarea>
                    <v-textarea
                      v-else
                      solo
                      placeholder="Resolución"
                      v-model="resolucion"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :value="incidenceSelected.state"
                      disabled
                      v-if="incidenceSelected"
                      placeholder="Estado"
                      :items="estadoItems"
                      ref="estado"
                      :rules="reglaRequerido"
                    ></v-select>
                    <v-select
                      v-else
                      placeholder="Estado"
                      :items="estadoItems"
                      v-model="estado"
                      :rules="reglaRequerido"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" align="end">
                    <v-btn
                      small
                      color="success"
                      @click="confirmar()"
                    >Confirmar</v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      small
                      color="error"
                      @click="cancelar()"
                    >Cancelar</v-btn>
                  </v-col>
                </v-row>
            </v-form>
        </v-card>
      <warning-dialog :dialog="showWarningDialog" @close="showWarningDialog = false" />
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import IncidenceService from '@/services/IncidenceService'
import WarningDialog from './WarningDialog.vue'

export default {
  components: { WarningDialog },
  name: 'IncidencesForm',
  computed: {
    ...mapState([
      'activeTunnel',
      'incidenceSelected',
      'incidences',
      'user'
    ])
  },
  data: () => ({
    tipoItems: ['Grave', 'Medio', 'Leve'],
    causaItems: ['Avería', 'Fallo en instalación', 'Presencia de peatones', 'Accidente', 'Otro'],
    actoresExternosItems: ['Policía', 'Bomberos', 'Ambulancias', 'Otros'],
    afeccionesItems: ['Ventilación', 'Iluminación', 'Firme', 'Otros'],
    estadoItems: ['Abierta', 'Resuelta', 'En pausa'],
    tipo: null,
    fecha: null,
    ubicacion: null,
    carreteraItems: ['A-1'],
    road: null,
    pk: null,
    causa: null,
    actoresExternos: null,
    daños: null,
    afecciones: null,
    resolucion: null,
    estado: 'Abierta',
    activePicker: null,
    menu: false,
    reglaRequerido: [(value) => !!value || 'Campo requerido'],
    fechaFormateada: null,
    ubicacionItems: ['Tubo 1', 'Tubo 2'],
    showWarningDialog: false
  }),
  methods: {
    confirmar () {
      if (this.$refs.form.validate()) {
        if (this.incidenceSelected) {
          const params = {
            road: this.$refs.road.lazyValue,
            pk: this.$refs.pk.lazyValue
          }
          IncidenceService.getCoords(params)
            .then(({ data }) => {
              const coordenadas = { type: 'Point', coordinates: [data[0].x, data[0].y] }
              const body = {
                tunnelId: this.activeTunnel.id,
                type: this.$refs.tipo.lazyValue,
                date: this.fecha,
                location: this.$refs.ubicacion.lazyValue,
                geom: coordenadas,
                reason: this.$refs.causa.lazyValue,
                external_actors: this.$refs.actoresExternos.lazyValue,
                external_damage: this.$refs.daños.lazyValue,
                internal_damage: this.$refs.afecciones.lazyValue,
                resolution: this.$refs.resolucion.lazyValue,
                state: this.$refs.estado.lazyValue,
                road: this.$refs.road.lazyValue,
                pk: this.$refs.pk.lazyValue
              }
              IncidenceService.editIncidence(this.incidenceSelected.id, body)
                .then(() => {
                  this.cancelar()
                })
                .catch((error) => {
                  console.log(error)
                  this.cancelar()
                })
            })
            .catch((error) => {
              console.log(error)
              this.$store.dispatch('setWarningDialogMsg', 'El PK introducido no es válido')
              this.showWarningDialog = true
            })
        } else {
          const params = {
            road: this.road,
            pk: this.pk
          }
          IncidenceService.getCoords(params)
            .then(({ data }) => {
              const coordenadas = { type: 'Point', coordinates: [data[0].x, data[0].y] }
              const body = {
                tunnelId: this.activeTunnel.id,
                type: this.tipo,
                date: this.fecha,
                location: this.ubicacion,
                geom: coordenadas,
                reason: this.causa,
                external_actors: this.actoresExternos,
                external_damage: this.daños,
                internal_damage: this.afecciones,
                resolution: this.resolucion,
                state: this.estado,
                road: this.road,
                pk: this.pk
              }
              IncidenceService.createIncidence(body)
                .then(() => {
                  this.cancelar()
                })
                .catch(() => {
                  this.$store.dispatch('setWarningDialogMsg', 'Error al crear la incidencia')
                  this.showWarningDialog = true
                  this.cancelar()
                })
            })
            .catch((error) => {
              console.log(error)
              this.$store.dispatch('setWarningDialogMsg', 'El PK introducido no es válido')
              this.showWarningDialog = true
            })
        }
      }
    },
    cancelar () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.$emit('close')
      this.$store.dispatch('setIncidenceSelected', false)
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    parseDate (fechaFormateada) {
      if (!fechaFormateada) {
        return null
      } else {
        const [day, month, year] = fechaFormateada.split('-')
        return `${year}-${month}-${day}`
      }
    }
  },
  watch: {
    menu1 (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    fecha (val) {
      const [year, month, day] = val.split('-')
      this.fechaFormateada = `${day}-${month}-${year}`
    },
    incidenceSelected (val) {
      if (val) {
        this.fechaFormateada = this.parseDate(val.date)
      }
    }
  },
  props: {
    dialog: null
  }
}
</script>
