<template>
  <v-dialog v-if="user" :value="dialog" persistent width="350px">
    <v-card class="pa-5">
      <v-form ref="form" lazy-validation>
        <v-row class="justify-center">
          <v-col cols="12">
            <v-select
              v-if="incidenceSelected"
              :value="incidenceSelected.state"
              placeholder="Estado"
              :items="estadoItems"
              ref="estado"
              :rules="reglaRequerido"
            ></v-select>
          </v-col>
          <v-col cols="5">
            <v-btn
              small
              color="success"
              @click="confirmar()"
            >Confirmar</v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn
              small
              color="error"
              @click="cancelar()"
            >Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import IncidenceService from '@/services/IncidenceService'

export default {
  name: 'IncidencesStateForm',
  computed: {
    ...mapState([
      'activeTunnel',
      'incidenceSelected',
      'incidences',
      'user'
    ])
  },
  data: () => ({
    estadoItems: ['Abierta', 'Resuelta', 'En pausa'],
    reglaRequerido: [(value) => !!value || 'Campo requerido']
  }),
  methods: {
    confirmar () {
      if (this.$refs.form.validate()) {
        const body = {
          state: this.$refs.estado.lazyValue
        }
        IncidenceService.editIncidence(this.incidenceSelected.id, body)
          .then(({ data }) => {
            this.incidences.forEach((incidence) => {
              if (incidence.id === data.id) {
                incidence.state = data.state
              }
            })
            this.cancelar()
          })
          .catch((error) => {
            console.log(error)
            this.cancelar()
          })
      }
    },
    cancelar () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.$emit('close')
      this.$store.dispatch('setIncidenceSelected', false)
    }
  },
  props: {
    dialog: null
  }
}

</script>
