import { apiClient } from './ApiClient'

export default {
  getRooms (roomsPerPage, lastRoom) {
    return apiClient.get('/chat/rooms', { params: { roomsPerPage, lastRoom: lastRoom?.lastUpdated } })
  },
  createRoom (room) {
    return apiClient.post('/chat/rooms', room)
  },
  updateRoom (roomId, payload) {
    return apiClient.put(`/chat/rooms/${roomId}`, payload)
  },
  deleteRoom (roomId) {
    return apiClient.delete(`/chat/rooms/${roomId}`)
  },
  getInvitableUsersForRoom (roomId) {
    return apiClient.get(`/chat/rooms/${roomId}/invitableUsers`)
  },
  addUsersToRoom (roomId, userIds) {
    return apiClient.post(`/chat/rooms/${roomId}/inviteUsers`, userIds)
  },
  getRemovableUsersForRoom (roomId) {
    return apiClient.get(`/chat/rooms/${roomId}/removableUsers`)
  },
  getRoomUsers (roomId) {
    return apiClient.get(`/chat/rooms/${roomId}/users`)
  },
  removeUsersFromRoom (roomId, userIds) {
    return apiClient.post(`/chat/rooms/${roomId}/removeUsers`, userIds)
  },
  getMessages (roomId, messagesPerPage, lastLoadedMessage) {
    return apiClient.get(`/chat/rooms/${roomId}/messages`, { params: { messagesPerPage, lastLoadedMessage } })
  },
  addMessage (roomId, message) {
    return apiClient.post(`/chat/rooms/${roomId}/messages`, message)
  },
  updateMessage (roomId, messageId, payload) {
    return apiClient.put(`/chat/rooms/${roomId}/messages/${messageId}`, payload)
  }
}
