<template>
  <v-card>
    <v-card-title class="text-h5"> Información del túnel </v-card-title>

    <v-card-text v-if="tunnel && tunnel.info && tunnel.info.data.length > 0">
      <v-btn-toggle v-model="tb" rounded mandatory>
        <v-btn v-for="(tube, i) in tunnel.info.data" :key="i">
          {{ tube.label }}
        </v-btn>
      </v-btn-toggle>

      <v-data-table
        v-for="(info, i) in tunnel.info.data[tb].data"
        :key="i"
        :headers="tableHeaders"
        :items="info.data"
        dense
        class="elevation-3 mt-2 tunnel-info-table"
        hide-default-header
        hide-default-footer
        :items-per-page="100"
        no-data-text=""
        :height="Math.min(info.data.length * 32, 128)"
      >
        <template v-slot:top>
          <div class="text-overline pl-2">{{ info.label }}</div>
        </template>
        <template v-slot:[`item.value`]="{ item }">
          <span v-if="item.type === 'number'">{{
            item.value.toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })
          }}</span>
          <span v-else-if="item.type === 'boolean'">
            {{ item.value ? '\u{2705}' : '\u{274C}' }}
          </span>
          <span v-else>{{ item.value }}</span>
        </template>
      </v-data-table>

    </v-card-text>
    <v-card-text v-else>
      <h2>No hay datos para el tunel seleccionado</h2>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="green darken-1" text @click="close"> Aceptar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'InfoDialog',
  props: {
    tunnel: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tb: 0,
    tableHeaders: [
      {
        text: 'Campo',
        align: 'start',
        value: 'label'
      },
      { text: 'Valor', value: 'value' }
    ]
  }),
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.tunnel-info-table td {
  width: 50%;
}
</style>
