import { apiClient, gmaoApiClient, scadaApiClient } from './ApiClient'

export default {
  getAssets (tunnelId) {
    return apiClient.get('/api/tunnels/' + tunnelId + '/assets')
  },
  getAsset (id, tunnelId = null) {
    if (tunnelId !== null) {
      return apiClient.get('/api/tunnels/' + tunnelId + '/assets/' + id)
    } else {
      return apiClient.get('/api/assets/' + id)
    }
  },
  getAssetIfc (tunnelId, modelId, expressId) {
    return apiClient.get('/api/tunnels/' + tunnelId + '/assetsIfc/' + modelId + '_' + expressId)
  },
  getGmaoInfo (id) {
    return gmaoApiClient.get('/dameElemento/id_elemento=' + id)
  },
  getScadaInfo (id) {
    return scadaApiClient.get(id)
  }
}
