<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>BIMEXTUNEL - COMUNICACIONES</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="$emit('close')"
              >
                Volver al mapa
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <chat-window
            v-if="user"
            :height="screenHeight"
            :current-user-id="user.id"
            :text-messages="spanishTextMessages"
            :show-audio="false"
            :show-files="false"
            :show-reaction-emojis="false"

            :rooms="rooms"
            :rooms-loaded="roomsLoaded"
            :room-actions="roomActions"
            :menu-actions="roomActions"
            @add-room="addRoom"
            @room-action-handler="menuActionHandler"
            @menu-action-handler="menuActionHandler"
            @fetch-more-rooms="fetchMoreRooms"

            :messages="messages"
            :messages-loaded="messagesLoaded"
            @fetch-messages="fetchMessages"
            @send-message="sendMessage"
            @edit-message="editMessage"
            @delete-message="deleteMessage"
            :message-actions="messageActions"

            :username-options="usernameOptions"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <new-room-dialog :dialog="showAddRoomDialog" @close="showAddRoomDialog = false"/>
    <invite-user-dialog :dialog="showInviteUserDialog" :items="invitableUsers" @close="closeInviteUserDialog"/>
    <remove-user-dialog :dialog="showRemoveUserDialog" :items="removableUsers" @close="closeRemoveUserDialog"/>
    <confirmation-dialog ref="confirmDlg" />
  </div>
</template>

<script>
import ChatService from '@/services/ChatService'
import ChatWindow from 'vue-advanced-chat'
import NewRoomDialog from '@/components/chat/NewRoomDialog.vue'
import InviteUserDialog from '@/components/chat/InviteUserDialog.vue'
import RemoveUserDialog from '@/components/chat/RemoveUserDialog.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { mapState } from 'vuex'
import { parseTimestamp, formatTimestamp } from '@/utils/dates'
import { listenWebSocket, closeWebSocket } from '@/utils/websockets'

import 'vue-advanced-chat/dist/vue-advanced-chat.css'

export default {
  components: {
    ChatWindow,
    NewRoomDialog,
    InviteUserDialog,
    RemoveUserDialog,
    ConfirmationDialog
  },
  props: {
    dialog: null
  },

  data: () => ({
    roomId: -1,
    rooms: [],
    roomsLoaded: false,
    roomsPerPage: 15,
    startRoom: null,
    endRoom: null,
    loadingRooms: true,
    loadingLastMessageByRoom: 0,
    roomsLoadedCount: false,
    showAddRoomDialog: false,
    invitableUsers: [],
    showInviteUserDialog: false,
    removableUsers: [],
    showRemoveUserDialog: false,
    roomActions: [
      { name: 'inviteUser', title: 'Añadir usuario' },
      { name: 'removeUser', title: 'Eliminar usuario' },
      { name: 'deleteRoom', title: 'Eliminar sala' }
    ],
    messages: [],
    messagesLoaded: false,
    messagesPerPage: 20,
    lastLoadedMessage: null,
    previousLastLoadedMessage: null,
    spanishTextMessages: {
      ROOMS_EMPTY: 'Sin conversaciones',
      ROOM_EMPTY: 'Ninguna conversación seleccionada',
      NEW_MESSAGES: 'Mensajes nuevos',
      MESSAGE_DELETED: 'Mensaje eliminado',
      MESSAGES_EMPTY: 'No hay mensajes',
      CONVERSATION_STARTED: 'Conversación iniciada el ',
      TYPE_MESSAGE: 'Escribir aquí',
      SEARCH: 'Buscar',
      IS_ONLINE: 'está en línea',
      LAST_SEEN: 'última conexión ',
      IS_TYPING: 'escribiendo...',
      CANCEL_SELECT_MESSAGE: 'Borrar selección'
    },
    messageActions: [
      {
        name: 'editMessage',
        title: 'Editar',
        onlyMe: true
      },
      {
        name: 'deleteMessage',
        title: 'Borrar',
        onlyMe: true
      }
    ],
    usernameOptions: {
      minUsers: 0,
      currentUser: true
    },
    messagesWebSocket: null,
    roomsWebSocket: null
  }),
  computed: {
    ...mapState(['user']),
    loadedRooms () {
      return this.rooms.slice(0, this.roomsLoadedCount)
    },
    screenHeight () {
      return 'calc(100vh - 64px)'
    }
  },
  methods: {
    listenRoomMessages (room) {
      const callback = (e) => {
        const data = JSON.parse(e.data)
        data._id = data.id

        const room = this.rooms.find(x => x.roomId === data.roomId)

        if (room == null && room.roomId !== this.roomId) {
          return
        }

        const messageIndex = this.messages.findIndex(x => x._id === data._id)

        const formattedMessage = this.formatMessage(room, data)

        if (messageIndex === -1) {
          this.messages = [...this.messages, formattedMessage]
        } else {
          this.messages[messageIndex] = formattedMessage
          this.messages = [...this.messages]
        }

        ChatService.updateRoom(data.roomId, { lastUpdated: data.timestamp })
      }

      listenWebSocket(this.messagesWebSocket, room, callback)
    },
    listenRoomsUpdates () {
      const callback = (e) => {
        const data = JSON.parse(e.data)

        // Una sala ha cambiado. Hay varias opciones:
        // 1. Era una sala en la que no estaba el usuario actual
        //    1.1. Y sigue sin estar => IGNORAR EL MENSAJE
        //    1.2. Ahora sí está => AÑADIRLA AL ARRAY DE SALAS
        // 2. Era una sala en la que sí estaba el usuario actual
        //    2.1. Y ahora no está => ELIMINARLA DE LA LISTA DE SALAS (SI ES LA ACTIVA, ELEGIR OTRA)
        //    2.2. Sigue estando => ACTUALIZAR NOMBRE, ULTIMA MODIFICACION Y LISTA DE USUARIOS

        let room = this.rooms.find(x => x.roomId === data.roomId)

        // TODO: Evitar los fetchRooms donde no sea necesario, porque borran y vuelven a cargar todas las salas
        if (room == null) {
          if (!data.users || !data.users.includes(this.user.id)) {
            return
          } else {
            this.fetchRooms()
            return
          }
        }

        if (data.users && data.users.includes(this.user.id)) {
          return
        }
        this.fetchRooms()

        room = this.rooms.find(x => x.roomId === data.roomId)
        if (room == null) {
          this.roomId = -1
        }
      }

      listenWebSocket(this.roomsWebSocket, 'room_update', callback)
    },
    closeWebSockets () {
      closeWebSocket(this.messagesWebSocket)
      closeWebSocket(this.roomsWebSocket)
    },
    resetRooms () {
      this.loadingRooms = true
      this.loadingLastMessageByRoom = 0
      this.roomsLoadedCount = 0
      this.rooms = []
      this.roomsLoaded = true
      this.startRoom = null
      this.endRoom = null
      // this.roomsListeners.forEach(listener => listener())
      // this.roomsListeners = []
      this.resetMessages()
    },
    resetMessages () {
      this.messages = []
      this.messagesLoaded = false
      this.lastLoadedMessage = null
      this.previousLastLoadedMessage = null
      // this.listeners.forEach(listener => listener())
      // this.listeners = []
    },
    addRoom () {
      this.showAddRoomDialog = true
    },
    fetchRooms () {
      this.resetRooms()
      this.fetchMoreRooms()
    },
    async fetchMoreRooms () {
      if (this.endRoom && !this.startRoom) {
        this.roomsLoaded = true
        return
      }

      const { data } = await ChatService.getRooms(this.roomsPerPage, this.startRoom)

      this.roomsLoaded = data.length === 0 || data.length < this.roomsPerPage

      if (this.startRoom) {
        this.endRoom = this.startRoom
      }

      // this.startRoom = docs[docs.length - 1]
      this.startRoom = data[data.length - 1]

      const formattedRooms = []
      data.forEach(room => {
        formattedRooms.push({
          ...room,
          index: room.lastUpdated,
          lastMessage: {
            content: 'Sala creada',
            timestamp: formatTimestamp(room.lastUpdated)
          }
        })
      })

      this.rooms = this.rooms.concat(formattedRooms)
      // formattedRooms.forEach(room => this.listenLastMessage(room))

      if (!this.rooms.length) {
        this.loadingRooms = false
        this.roomsLoadedCount = 0
      }

      // this.listenUsersOnlineStatus(formattedRooms)
      // this.listenRooms(query)
    },
    inviteUser (roomId) {
      ChatService.getInvitableUsersForRoom(roomId)
        .then(({ data }) => {
          this.invitableUsers = data
          this.showInviteUserDialog = true
          this.roomId = roomId
        })
        .catch(err => {
          console.log(err)
        })
    },
    async closeInviteUserDialog (selectedUsers) {
      this.showInviteUserDialog = false
      this.invitableUsers = []

      if (selectedUsers && selectedUsers.length) {
        await ChatService.addUsersToRoom(this.roomId, selectedUsers)

        const roomUsers = await ChatService.getRoomUsers(this.roomId)
        const room = this.rooms.find(x => x.roomId === this.roomId)
        room.users = roomUsers.data
      }

      this.roomId = -1
    },
    removeUser (roomId) {
      ChatService.getRemovableUsersForRoom(roomId)
        .then(({ data }) => {
          this.removableUsers = data
          this.showRemoveUserDialog = true
          this.roomId = roomId
        })
        .catch(err => {
          console.log(err)
        })
    },
    async closeRemoveUserDialog (selectedUsers) {
      this.showRemoveUserDialog = false
      this.removableUsers = []

      if (selectedUsers && selectedUsers.length) {
        await ChatService.removeUsersFromRoom(this.roomId, selectedUsers)
      }

      this.roomId = -1
    },
    async deleteRoom (roomId) {
      if (
        await this.$refs.confirmDlg.open(
          'Confirmar',
          '¿Seguro que quieres eliminar esta sala?'
        )
      ) {
        await ChatService.deleteRoom(roomId)
      }
    },
    menuActionHandler ({ action, roomId }) {
      switch (action.name) {
        case 'inviteUser':
          return this.inviteUser(roomId)
        case 'removeUser':
          return this.removeUser(roomId)
        case 'deleteRoom':
          return this.deleteRoom(roomId)
      }
    },
    async fetchMessages ({ room, options = {} }) {
      if (options.reset) {
        this.resetMessages()
        this.roomId = room.roomId
      }

      if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
        this.messagesLoaded = true
        return
      }

      this.roomId = room.roomId

      const { data: messages } = await ChatService
        .getMessages(room.roomId, this.messagesPerPage, this.lastLoadedMessage)

      if (this.roomId !== room.roomId) return

      if (messages.length === 0 || messages.length < this.messagesPerPage) {
        setTimeout(() => (this.messagesLoaded = true), 0)
      }

      if (options.reset) this.messages = []

      messages.forEach(message => {
        const formattedMessage = this.formatMessage(room, message)
        this.messages.unshift(formattedMessage)
      })

      if (this.lastLoadedMessage) {
        this.previousLastLoadedMessage = this.lastLoadedMessage
      }

      this.lastLoadedMessage = messages[messages.length - 1]
      this.listenRoomMessages(`room_${room.roomId}`)
    },
    markMessagesSeen (room, message) {
      if (message.sender_id !== this.user.id && (!message.seen || !message.seen[this.currentUserId])) {
        ChatService.updateMessage(room.roomId, message.id, {
          [`seen.${this.currentUserId}`]: new Date()
        })
      }
    },
    formatMessage (room, message) {
      const user = room.users.find(user => message.senderId === user._id)

      let username = ''

      if (user) {
        username = `${user.name} (${user.username})`
      }

      const formattedMessage = {
        ...message,
        ...{
          senderId: message.senderId,
          _id: message._id,
          // seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, 'HH:mm'),
          date: parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
          username,
          distributed: true,
          lastMessage: { ...message.lastMessage, senderId: message.senderId }
        }
      }
      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.senderId
          }
        }
      }

      return formattedMessage
    },

    async sendMessage ({ content, roomId, replyMessage }) {
      const messageDate = new Date()
      const message = {
        senderId: this.user.id,
        content,
        timestamp: messageDate
      }

      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        }
      }

      await ChatService.addMessage(roomId, message)
      // No necesito añadir el mensaje porque al insertarlo en la BD saltará el evento
    },

    async editMessage ({ messageId, newContent, roomId }) {
      const payload = {
        content: newContent,
        edited: new Date()
      }

      await ChatService.updateMessage(roomId, messageId, payload)
    },

    async deleteMessage ({ message, roomId }) {
      const payload = {
        deleted: new Date()
      }

      await ChatService.updateMessage(roomId, message._id, payload)
    }
  },
  watch: {
    dialog (value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchRooms()
        this.listenRoomsUpdates()
        // TODO: Mandar notificación de que el usuario está ONLINE
      } else if (!value) {
        this.closeWebSockets()
      }
    }
  }
}
</script>
