var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vl-map',{ref:"map",attrs:{"load-tiles-while-animating":true,"load-tiles-while-interacting":true},on:{"mounted":_vm.onMapMounted}},[_c('vl-view',{ref:"view",attrs:{"zoom":_vm.zoom,"center":_vm.center,"rotation":_vm.rotation},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.center=$event},"update:rotation":function($event){_vm.rotation=$event}}}),_c('vl-layer-group',{attrs:{"id":"capas-base"},on:{"created":function($event){return _vm.setLayerOptions($event, {
        title: 'Mapa base',
        displayInLayerSwitcher: true,
        openInLayerSwitcher: true,
      })}}},[_c('vl-layer-tile',{attrs:{"id":"osm"},on:{"created":function($event){return _vm.setLayerOptions($event, {
          title: 'OSM',
          baseLayer: true,
          visible: true,
        })}}},[_c('vl-source-osm')],1),_c('vl-layer-group',{attrs:{"id":"pnoa"},on:{"created":function($event){return _vm.setLayerOptions($event, {
          title: 'PNOA',
          baseLayer: true,
          visible: false,
        })}}},[_c('vl-layer-tile',{attrs:{"id":"pnoa-hibrido"},on:{"created":function($event){return _vm.setLayerOptions($event, {
          displayInLayerSwitcher: false
        })}}},[_c('vl-source-wmts',{attrs:{"url":"https://www.ign.es/wmts/pnoa-ma?","layer-name":"OI.OrthoimageCoverage","matrix-set":"EPSG:3857","format":"image/jpeg","styleName":""}})],1),_c('vl-layer-tile',{attrs:{"id":"callejero-hibrido"},on:{"created":function($event){return _vm.setLayerOptions($event, {
          displayInLayerSwitcher: false
        })}}},[_c('vl-source-wmts',{attrs:{"url":"https://www.ign.es/wmts/ign-base?","layer-name":"IGNBaseOrto","matrix-set":"EPSG:3857","format":"image/png","styleName":""}})],1)],1)],1),_c('vl-layer-group',{attrs:{"id":"planos","opacity":0.5},on:{"created":function($event){return _vm.setLayerOptions($event, {
        title: 'Planos',
        visible: false,
        displayInLayerSwitcher: true,
        openInLayerSwitcher: true,
      })}}},[_c('vl-layer-tile',{attrs:{"id":"11M4870_PB_1_PG"},on:{"created":function($event){_vm.setLayerOptions($event, {
          title: 'Construcción (1990)',
          visible: false,
        })}}},[_c('vl-source-tile-wms',{attrs:{"url":_vm.layerUrls[0],"layers":"hojasimgbd,hojasidxbd","format":"image/png","extParams":"{TILED: true}"}})],1),_c('vl-layer-tile',{attrs:{"id":"52M14710_PB_4_PS"},on:{"created":function($event){return _vm.setLayerOptions($event, {
          title: 'Adecuación R.D. 635/2006',
          visible: false,
        })}}},[_c('vl-source-tile-wms',{attrs:{"url":_vm.layerUrls[1],"layers":"hojasimgbd,hojasidxbd","format":"image/png","extParams":"{TILED: true}"}})],1)],1),_c('vl-layer-vector',{attrs:{"id":"incidences"},on:{"created":function($event){return _vm.setLayerOptions($event, {
        title: 'Incidencias',
        visible: true,
      })}}},[_c('vl-source-vector',{attrs:{"features":_vm.incidencesGeom},on:{"update:features":function($event){_vm.incidencesGeom=$event}}}),_c('vl-style-func',{attrs:{"function":_vm.applyIncidenceStyle}})],1),_c('vl-layer-vector',{attrs:{"id":"tunnel","visible":_vm.tunnelHull.length > 0},on:{"created":function($event){return _vm.setLayerOptions($event, {
        displayInLayerSwitcher: false
      })}}},[_c('vl-style',[_c('vl-style-stroke',{attrs:{"color":"blue","width":4}})],1),_c('vl-source-vector',{attrs:{"features":_vm.tunnelHull},on:{"update:features":function($event){_vm.tunnelHull=$event}}})],1),_c('vl-layer-vector',{attrs:{"id":"visibleAssets","visible":_vm.visibleAssetsHulls.length > 0},on:{"created":function($event){return _vm.setLayerOptions($event, {
        displayInLayerSwitcher: false
      })}}},[_c('vl-style',[_c('vl-style-stroke',{attrs:{"color":"blue","width":1}}),_c('vl-style-fill',{attrs:{"color":"rgba(255, 255, 255, 0.0)"}})],1),_c('vl-source-vector',{attrs:{"features":_vm.visibleAssetsHulls},on:{"update:features":function($event){_vm.visibleAssetsHulls=$event}}})],1),_c('vl-layer-vector',{attrs:{"id":"measuresLayer"},on:{"created":function($event){return _vm.setLayerOptions($event, {
        displayInLayerSwitcher: false
      })}}},[_c('vl-style-func',{attrs:{"function":_vm.applyVectorStyle}}),_c('vl-source-vector',{ref:"measures",attrs:{"ident":"measures","features":_vm.measures},on:{"update:features":function($event){_vm.measures=$event}}})],1),_c('vl-interaction-select',{attrs:{"features":_vm.selectedFeatures,"layers":_vm.selectableLayers,"active":_vm.activeTool === 'select'},on:{"update:features":function($event){_vm.selectedFeatures=$event},"update:layers":function($event){_vm.selectableLayers=$event}}},[_c('vl-style-func',{attrs:{"function":_vm.applySelectedStyle}})],1),_c('vl-interaction-draw',{attrs:{"type":_vm.measurementType,"source":"measures","stop-click":true,"active":_vm.activeTool.startsWith('measure:')},on:{"drawstart":_vm.measureStart,"drawend":_vm.measureEnd}},[_c('vl-style-func',{attrs:{"function":_vm.applyInteractionStyle}})],1),_c('vl-interaction-modify',{attrs:{"source":"measures","active":_vm.modifyActive}},[_c('vl-style-func',{attrs:{"function":_vm.applyModifyStyle}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }