<template>
  <div>
    <div id="model-viewer" />
    <div id="visibility-checkbox" v-if="ifcBaseModel2">
      <input type="checkbox" id="ckb" v-model="checked">
      <label for="ckb"> Estructura transparente</label>
    </div>
  </div>
</template>

<script>
import { IfcViewerAPI } from 'web-ifc-viewer'
import { mapState } from 'vuex'

export default {
  name: 'IfcViewer',
  props: {},
  data: () => ({
    viewer: null,
    ifcBaseModel: null,
    ifcBaseModel2: null,
    ifcModels: [],
    checked: false
  }),
  methods: {
    redraw () {
      this.viewer.context.updateAspect()
    },
    centerElement (modelId, expressId) {
      if (modelId > 0 && expressId > 0) {
        this.viewer.IFC.selector.pickIfcItemsByID(modelId, [expressId], true)
      }
    }
  },
  computed: {
    ...mapState(['activeTunnel', 'activeAsset'])
  },
  watch: {
    async activeTunnel (tunnel) {
      if (!tunnel || !tunnel.ifcBase) {
        if (this.viewer != null) {
          await this.viewer.dispose()
        }
        this.$emit('close3d')
        this.viewer = null
        this.$store.dispatch('setIfcLoaded', false)
        return
      }

      if (this.viewer == null) {
        const container = document.getElementById('model-viewer')
        this.viewer = new IfcViewerAPI({ container })
        this.viewer.IFC.setWasmPath('../IFCjs/')
        this.redraw()
        container.onmousemove = () => this.viewer.IFC.selector.prePickIfcItem()
        container.onclick = async () => {
          const item = await this.viewer.IFC.selector.pickIfcItem(false)
          if (item != null) {
            this.$store.dispatch('selectAssetIfc', { modelId: item.modelID, expressId: item.id })
          }
        }
      }

      this.ifcBaseModel = await this.viewer.IFC.loadIfcUrl(tunnel.ifcBase)

      const models = []
      for (let i = 0; i < tunnel.ifc.length; i++) {
        const model = await this.viewer.IFC.loadIfcUrl(tunnel.ifc[i])
        models.push(model)
      }

      if (tunnel.ifcBase2) {
        this.ifcBaseModel2 = await this.viewer.IFC.loadIfcUrl(tunnel.ifcBase2)
      }

      this.$store.dispatch('setIfcLoaded', true)
      this.ifcModels = models

      this.viewer.context.items.pickableIfcModels = this.ifcModels
    },
    checked (value) {
      this.ifcBaseModel2.visible = !value
    }
  }
}
</script>

<style scoped>
#model-viewer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#visibility-checkbox {
  position: absolute;
  top: 0 !important;
  margin: 16px;
  background-color: rgba(255,255,255,0.8);
  display: inline-block;
  padding: 10px;
}
</style>
