<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Crear usuario</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="name"
              label="Nombre"
              required
              :rules="nameRules"
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="E-mail"
              required
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="login"
              label="Nombre de usuario"
              required
              :rules="loginRules"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="showPassword ? 'text' : 'password'"
              label="Contraseña"
              counter
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-select
              v-model="role"
              :items="roles"
              :rules="[v => !!v || 'El rol es obligatorio']"
              label="Rol"
              required
            ></v-select>
          </v-form>
          <p class="red--text text-center">{{ error }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')"> Cerrar </v-btn>
          <v-btn :disabled="!valid" color="success" @click="validate"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UserService from '@/services/UserService'
import { mapState } from 'vuex'

export default {
  props: {
    dialog: null
  },
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'El nombre es obligatorio'
    ],
    email: '',
    emailRules: [
      v => !!v || 'El correo electrónico es obligatorio',
      v => /.+@.+\..+/.test(v) || 'La dirección de correo no es válida'
    ],
    login: '',
    loginRules: [
      v => !!v || 'El nombre de usuario es obligatorio',
      v => /^[a-zA-Z0-9]+$/.test(v) || 'El nombre de usuario solo puede tener letras y números'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'La contraseña es obligatoria',
      v => (v && v.length >= 6) || 'Mínimo 6 caracteres',
      v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'La contraseña debe incluir una minúscula, una mayúscula y un número'
    ],
    showPassword: false,
    role: null,
    error: null
  }),
  computed: {
    ...mapState(['roles'])
  },
  methods: {
    async validate () {
      if (this.$refs.form.validate()) {
        // Los campos son validos, así que puedo intentar guardar en la BD
        const user = {
          login: this.login,
          password: this.password,
          name: this.name,
          email: this.email,
          role: this.role
        }

        try {
          await UserService.saveUser(user)

          // Llegados a este punto, el usuario se ha creado
          this.$emit('close', true)
          this.$refs.form.reset()
          this.error = ''
        } catch (error) {
          this.error = error.response.data.msg
        }
      }
    }
  }
}
</script>
