<template>
  <v-dialog v-if="incidences" :value="dialog" persistent max-width="450px">
    <v-card class="pa-3">
      <p class="text-center"><v-icon large :left="true" color="orange"> mdi-alert-circle </v-icon>{{message}}</p>
      <v-row>
        <v-col class="text-center">
          <v-btn @click="$emit('close')" small>Aceptar</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IncidenceDialog',
  computed: {
    ...mapState([
      'incidences'
    ]),
    message () {
      const num = this.incidences.filter(i => i.state === 'Abierta').length

      if (num === 1) {
        return 'Hay 1 incidencia abierta'
      }

      return `Hay ${num} incidencias abiertas`
    }
  },
  props: {
    dialog: null
  }
}
</script>
