import axios from 'axios'

const isDev = process.env.NODE_ENV === 'development'

const apiBase = isDev ? 'http://localhost:3000/auth/' : '/auth/'

const apiClient = axios.create({
  baseURL: apiBase,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  login (credentials) {
    return apiClient.post('/login', credentials)
  }
}
