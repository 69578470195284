var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" Eventos SCADA - "+_vm._s(_vm.activeTunnel.name)+" ")]),_c('v-data-table',{attrs:{"items-per-page":10,"headers":_vm.headers,"items":_vm.events,"sort-by":['startDate'],"sort-desc":true,"no-data-text":"No hay eventos","footer-props":{
      itemsPerPageText: 'Elementos por página:',
      itemsPerPageOptions: [10,20,30,40],
      showFirstLastPage: true,
      firstIcon: 'mdi-skip-previous',
      lastIcon: 'mdi-skip-next',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right'
    }},scopedSlots:_vm._u([{key:`item.startDate`,fn:function({item}){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.startDate)))])]}},{key:`item.endDate`,fn:function({item}){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.endDate)))])]}},{key:`item.editar`,fn:function({item}){return [(item.asset)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.viewAsset(item.asset.key)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }