<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Eliminar usuarios de la sala</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="model"
                  :items="items"
                  multiple
                  chips
                  deletable-chips
                  :item-text="getItemText"
                  item-value="_id"
                  label="Usuarios"
                  prepend-icon="mdi-database-search"
                  hint="Selecciona los usuarios a eliminar"
                  no-data-text="No quedan otros usuarios en la sala"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close', []); model = null"> Cerrar </v-btn>
          <v-btn color="blue darken-1" text :disabled="!model || model.length === 0" @click="$emit('close', model); model = null"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: null,
    items: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    model: null
  }),
  methods: {
    getItemText (item) {
      return `${item.name} (${item.username})`
    }
  }
}
</script>
