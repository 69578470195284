<template>
  <v-row justify="center">
    <v-dialog :value="dialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">Crear nueva sala</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre"
                  required
                  v-model="roomName"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <p class="red--text text-center">{{ error }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')"> Cerrar </v-btn>
          <v-btn color="blue darken-1" text @click="validate"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ChatService from '@/services/ChatService'
import { mapState } from 'vuex'

export default {
  props: {
    dialog: null
  },
  data: () => ({
    roomName: '',
    error: null
  }),
  computed: {
    ...mapState(['user'])
  },
  methods: {
    validate: async function () {
      ChatService.createRoom({ roomName: this.roomName, roomUsers: [{ userId: this.user.id }] })
        .then(() => {
          this.$emit('close')
        })
        .catch((error) => {
          this.error = error.response.data.msg
        })
    }
  }
}
</script>
