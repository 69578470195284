import { apiClient } from './ApiClient'

export default {
  getUsers () {
    return apiClient.get('/api/users')
  },
  saveUser (user) {
    return apiClient.post('/api/users', user)
  },
  deleteUser (userId) {
    return apiClient.delete(`/api/users/${userId}`)
  }
}
