<template>
  <div>
    <v-row justify="center">
      <v-dialog
        :value="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>BIMEXTUNEL - GESTIÓN DE USUARIOS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="$emit('close')"
              >
                Volver al mapa
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="users"
              :search="search"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Filtrar"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-grey"
                    class="mb-2 white--text"
                    @click="showAddUserDialog = true"
                  >
                    Añadir usuario
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.role`]="{ item }">
                {{ getRole(item.role) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-show="item.id !== user.id"
                  small
                  @click="deleteUser(item.id)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <new-user-dialog :dialog="showAddUserDialog" @close="closeDialogAndUpdate"/>
    <confirmation-dialog ref="confirmDlg" />
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import NewUserDialog from '@/components/users/NewUserDialog.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { mapState } from 'vuex'

export default {
  components: {
    NewUserDialog,
    ConfirmationDialog
  },
  props: {
    dialog: null
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'Usuario', align: 'start', value: 'login' },
      { text: 'Nombre', align: 'start', value: 'name' },
      { text: 'e-mail', align: 'start', value: 'email' },
      { text: 'Rol', align: 'start', value: 'role' },
      { text: '', value: 'actions', sortable: false, width: '20px' }
    ],
    users: [],
    showAddUserDialog: false
  }),
  computed: {
    ...mapState(['user', 'roles']),
    screenHeight () {
      return 'calc(100vh - 64px)'
    }
  },
  methods: {
    async fetchUsers () {
      const { data } = await UserService.getUsers()
      this.users = data
    },
    closeDialogAndUpdate (update) {
      this.showAddUserDialog = false
      if (update) {
        this.fetchUsers()
      }
    },
    getRole (roleId) {
      const role = this.roles.find(x => x.value === roleId)

      return role?.text
    },
    async deleteUser (userId) {
      if (
        await this.$refs.confirmDlg.open(
          'Confirmar',
          '¿Seguro que quieres eliminar este usuario?'
        )
      ) {
        await UserService.deleteUser(userId)
        this.fetchUsers()
      }
    }
  },
  watch: {
    dialog (value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchUsers()
      }
    }
  }
}
</script>
