<template>
  <div class="pa-2">
    <v-btn block @click.stop="$emit('showTunnelInfo')">Info. General</v-btn>
    <v-btn block @click.stop="$emit('showIncidencesTab')">
      Gestor de incidencias
      <div v-if="incidences">
        <v-icon v-if="incidences.filter(i => i.state === 'Abierta').length > 0 && user.role === 'rst'" dense color="orange">mdi-circle-medium</v-icon>
      </div>
    </v-btn>
    <v-btn block @click.stop="$emit('showScadaEvents')">Mostrar eventos SCADA</v-btn>
    <v-btn
      block
      v-for="(link, index) in links"
      :key="index"
      @click="openLink(link.url)"
    >
      {{ link.title }}
    </v-btn>
    <v-btn block :disabled="!ifcLoaded" @click.stop="$emit('changeViewMode')">{{viewButtonText}}</v-btn>
    <v-btn block :disabled="!ifcLoaded" @click.stop="$emit('downloadIfc')">Descargar IFC</v-btn>
  </div>
</template>

<script>
import linksMixin from '@/mixins/linksMixin'
import { mapState } from 'vuex'

export default {
  name: 'TunnelLinks',
  mixins: [linksMixin],
  props: {
    links: {
      type: Array,
      required: true
    },
    currentView: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState([
      'ifcLoaded',
      'incidences',
      'user'
    ]),
    viewButtonText () {
      if (this.currentView === '2D') {
        return 'Cambiar a vista 3D'
      } else if (this.currentView === '3D') {
        return 'Cambiar a vista 2D'
      } else {
        return 'ERROR'
      }
    }
  }
}
</script>
