<template>
  <vl-map
    ref="map"
    :load-tiles-while-animating="true"
    :load-tiles-while-interacting="true"
    @mounted="onMapMounted"
  >
    <vl-view
      ref="view"
      :zoom.sync="zoom"
      :center.sync="center"
      :rotation.sync="rotation"
    ></vl-view>

    <vl-layer-group
      id="capas-base"
      @created="
        setLayerOptions($event, {
          title: 'Mapa base',
          displayInLayerSwitcher: true,
          openInLayerSwitcher: true,
        })
      "
    >
      <vl-layer-tile
        id="osm"
        @created="
          setLayerOptions($event, {
            title: 'OSM',
            baseLayer: true,
            visible: true,
          })
        "
      >
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>

      <vl-layer-group
        id="pnoa"
        @created="
          setLayerOptions($event, {
            title: 'PNOA',
            baseLayer: true,
            visible: false,
          })
        "
      >
        <vl-layer-tile
          id="pnoa-hibrido"
          @created="
          setLayerOptions($event, {
            displayInLayerSwitcher: false
          })
        ">
          <vl-source-wmts
            url="https://www.ign.es/wmts/pnoa-ma?"
            layer-name="OI.OrthoimageCoverage"
            matrix-set="EPSG:3857"
            format="image/jpeg"
            styleName=""
          ></vl-source-wmts>
        </vl-layer-tile>

        <vl-layer-tile
          id="callejero-hibrido"
          @created="
          setLayerOptions($event, {
            displayInLayerSwitcher: false
          })
        ">
          <vl-source-wmts
            url="https://www.ign.es/wmts/ign-base?"
            layer-name="IGNBaseOrto"
            matrix-set="EPSG:3857"
            format="image/png"
            styleName=""
          ></vl-source-wmts>
        </vl-layer-tile>
      </vl-layer-group>
    </vl-layer-group>

    <vl-layer-group
      id="planos"
      :opacity="0.5"
      @created="
        setLayerOptions($event, {
          title: 'Planos',
          visible: false,
          displayInLayerSwitcher: true,
          openInLayerSwitcher: true,
        })
      "
    >
      <vl-layer-tile
        id="11M4870_PB_1_PG"
        @created="
        setLayerOptions($event, {
            title: 'Construcción (1990)',
            visible: false,
          })
      ">
        <vl-source-tile-wms
          :url="layerUrls[0]"
          layers="hojasimgbd,hojasidxbd"
          format="image/png"
          extParams="{TILED: true}"
        ></vl-source-tile-wms>
      </vl-layer-tile>

      <vl-layer-tile
        id="52M14710_PB_4_PS"
        @created="
        setLayerOptions($event, {
            title: 'Adecuación R.D. 635/2006',
            visible: false,
          })
      ">
        <vl-source-tile-wms
          :url="layerUrls[1]"
          layers="hojasimgbd,hojasidxbd"
          format="image/png"
          extParams="{TILED: true}"
        ></vl-source-tile-wms>
      </vl-layer-tile>
    </vl-layer-group>

    <vl-layer-vector
      id="incidences"
      @created="
        setLayerOptions($event, {
          title: 'Incidencias',
          visible: true,
        })
      "
    >
      <vl-source-vector :features.sync="incidencesGeom" />
      <vl-style-func :function="applyIncidenceStyle" />
    </vl-layer-vector>

    <vl-layer-vector
      id="tunnel"
      :visible="tunnelHull.length > 0"
      @created="
        setLayerOptions($event, {
          displayInLayerSwitcher: false
        })
      ">
      <vl-style>
        <vl-style-stroke color="blue" :width="4"></vl-style-stroke>
      </vl-style>
      <vl-source-vector :features.sync="tunnelHull"></vl-source-vector>
    </vl-layer-vector>

    <vl-layer-vector
      id="visibleAssets"
      :visible="visibleAssetsHulls.length > 0"
      @created="
        setLayerOptions($event, {
          displayInLayerSwitcher: false
        })
      ">
      <vl-style>
        <vl-style-stroke color="blue" :width="1" />
        <vl-style-fill color="rgba(255, 255, 255, 0.0)" />
      </vl-style>
      <vl-source-vector :features.sync="visibleAssetsHulls" />
    </vl-layer-vector>

    <vl-layer-vector
      id="measuresLayer"
      @created="
        setLayerOptions($event, {
          displayInLayerSwitcher: false
        })
      ">
      <vl-style-func :function="applyVectorStyle" />
      <vl-source-vector ident="measures" ref="measures" :features.sync="measures"/>
    </vl-layer-vector>

    <vl-interaction-select
      :features.sync="selectedFeatures"
      :layers.sync="selectableLayers"
      :active="activeTool === 'select'"
    >
      <vl-style-func :function="applySelectedStyle" />
    </vl-interaction-select>

    <vl-interaction-draw
      :type="measurementType"
      source="measures"
      :stop-click="true"
      :active="activeTool.startsWith('measure:')"
      @drawstart="measureStart"
      @drawend="measureEnd"
    >
      <vl-style-func :function="applyInteractionStyle" />
    </vl-interaction-draw>

    <vl-interaction-modify
      source="measures"
      :active="modifyActive"
    >
      <vl-style-func :function="applyModifyStyle" />
    </vl-interaction-modify>
  </vl-map>
</template>

<script>
import { mapState } from 'vuex'
import { LineString, Point, Polygon } from 'ol/geom'
import { ScaleLine, Rotate } from 'ol/control'
import { Fill, Stroke, Style, Text, Icon, RegularShape, Circle as CircleStyle } from 'ol/style'
import { getArea, getLength } from 'ol/sphere'
import LayerSwitcher from 'ol-ext/control/LayerSwitcher'
import ControlBar from 'ol-ext/control/Bar'
import Toggle from 'ol-ext/control/Toggle'

import { reproject } from 'reproject'

import 'ol-ext/control/LayerSwitcher.css'
import 'ol-ext/control/Bar.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'

import proj4 from 'proj4'

const style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)'
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 0.5)',
    lineDash: [10, 10],
    width: 2
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.7)'
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    })
  })
})

const labelStyle = new Style({
  text: new Text({
    font: '14px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)'
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.7)'
    }),
    padding: [3, 3, 3, 3],
    textBaseline: 'bottom',
    offsetY: -15
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.7)'
    })
  })
})

const tipStyle = new Style({
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)'
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)'
    }),
    padding: [2, 2, 2, 2],
    textAlign: 'left',
    offsetX: 15
  })
})

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.7)'
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)'
    })
  }),
  text: new Text({
    text: 'Arrastra para modificar',
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)'
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.7)'
    }),
    padding: [2, 2, 2, 2],
    textAlign: 'left',
    offsetX: 15
  })
})

const segmentStyle = new Style({
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)'
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)'
    }),
    padding: [2, 2, 2, 2],
    textBaseline: 'bottom',
    offsetY: -12
  }),
  image: new RegularShape({
    radius: 6,
    points: 3,
    angle: Math.PI,
    displacement: [0, 8],
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)'
    })
  })
})

const segmentStyles = [segmentStyle]

const formatLength = function (line) {
  const length = getLength(line)
  let output
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + ' km'
  } else {
    output = Math.round(length * 100) / 100 + ' m'
  }
  return output
}

const formatArea = function (polygon) {
  const area = getArea(polygon)
  let output
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2'
  } else {
    output = Math.round(area * 100) / 100 + ' m\xB2'
  }
  return output
}

let tipPoint
function styleFunction (feature, segments, drawType, tip, measures) {
  const styles = [style]
  const geometry = feature.getGeometry()
  const type = geometry.getType()
  let point, label, line

  if (!drawType || drawType === type) {
    if (type === 'Polygon') {
      point = geometry.getInteriorPoint()
      label = formatArea(geometry)
      line = new LineString(geometry.getCoordinates()[0])
    } else if (type === 'LineString') {
      point = new Point(geometry.getLastCoordinate())
      label = formatLength(geometry)
      line = geometry
    }
  }

  if (segments && line) {
    let count = 0
    line.forEachSegment(function (a, b) {
      const segment = new LineString([a, b])
      const label = formatLength(segment)
      if (segmentStyles.length - 1 < count) {
        segmentStyles.push(segmentStyle.clone())
      }
      const segmentPoint = new Point(segment.getCoordinateAt(0.5))
      segmentStyles[count].setGeometry(segmentPoint)
      segmentStyles[count].getText().setText(label)
      styles.push(segmentStyles[count])
      count++
    })
  }

  if (label) {
    labelStyle.setGeometry(point)
    labelStyle.getText().setText(label)
    styles.push(labelStyle)
  }

  if (tip && type === 'Point' && (!measures || measures.length === 0)) {
    tipPoint = geometry
    tipStyle.getText().setText(tip)
    styles.push(tipStyle)
  }

  return styles
}

export default {
  name: 'MapControl',

  props: {
    visibleAssets: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    zoom: 6.8,
    center: [-412188.2444774167, 4926917.45401151],
    rotation: 0,
    activeAssetHull: [],
    visibleAssetsHulls: [],
    tunnelHull: [],
    incidencesGeom: [],
    selectedFeatures: [],
    selectableLayers: ['visibleAssets'],
    layerUrls: [
      'https://mapas.sigcar.es/map/?map=/srv/mapas/es_mapserver/MF/SIGPRO/sigproif.map&filtrohojas=11M4870_PB_1_PG&',
      'https://mapas.sigcar.es/map/?map=/srv/mapas/es_mapserver/MF/SIGPRO/sigproif.map&filtrohojas=52M14710_PB_4_PS&'
    ],
    activeTool: 'select',
    measurementType: 'LineString', // LineString or Polygon
    modifyActive: true,
    measurementTip: '',
    measures: []
  }),

  computed: {
    ...mapState(['activeTunnel', 'activeAsset', 'tunnelAssets', 'incidences'])
  },

  watch: {
    activeTunnel (tunnel) {
      if (!tunnel) {
        this.tunnelHull = []
        this.activeAssetHull = []
        this.visibleAssetsHulls = []
        this.incidencesGeom = []
        return
      }

      this.tunnelHull = [
        {
          type: 'Feature',
          geometry: tunnel.geom
        }
      ]

      this.$refs.view.$mountPromise.then(() => {
        const polygon = new Polygon([tunnel.geom.coordinates[0]])
        this.updateSize()
        return this.$refs.view.fit(polygon, {
          padding: [50, 50, 50, 50]
        })
      })
    },
    selectedFeatures (newValue) {
      if (newValue.length === 0) {
        this.$store.dispatch('selectAsset', null)
      } else {
        if (!this.activeAsset || newValue[0].properties.data.key !== this.activeAsset.key) {
          this.$store.dispatch('selectAsset', newValue[0].properties.data.key)
        }
      }
    },
    activeAsset (asset, oldAsset) {
      if (!asset) {
        this.selectedFeatures = []
        return
      }

      if (oldAsset && asset.key === oldAsset.key) {
        return
      }

      if (this.visibleAssetsHulls.length === 0) {
        return
      }

      const feature = this.visibleAssetsHulls.find(
        (x) => x.properties.data.key === asset.key
      )

      if (!feature) {
        return
      }

      this.selectedFeatures = [feature]
      this.$refs.view.$mountPromise.then(() => {
        const polygon = new Polygon([asset.geom.coordinates[0]])
        /*
        const extent = polygon.getExtent()
        const center = getCenter(extent)
        return this.$refs.view.setCenter(center)
        */
        this.updateSize()
        return this.$refs.view.fit(polygon, {
          padding: [50, 50, 50, 50],
          maxZoom: 22
        })
      })
    },
    visibleAssets (assetIds) {
      this.visibleAssetsHulls = []
      if (assetIds.length) {
        const tempArray = this.tunnelAssets.filter((x) =>
          assetIds.some((y) => y === x.id)
        )
        this.visibleAssetsHulls = tempArray.map(x => {
          return {
            type: 'Feature',
            geometry: x.geom,
            properties: {
              featureType: 'Asset',
              data: {
                id: x.id,
                key: x.key
              }
            }
          }
        })
      }
    },
    incidences (newValue) {
      if (!newValue) {
        this.incidencesGeom = []
        return
      }

      this.incidencesGeom = this.incidences
        .filter(x => x.state === 'Abierta')
        .map(x => {
          return {
            type: 'Feature',
            geometry: reproject(x.geom, proj4.WGS84, 'EPSG:3857'),
            properties: {
              featureType: 'Incidence',
              data: {
                level: x.type,
                reason: x.reason
              }
            }
          }
        })
    }
  },

  methods: {
    onMapMounted () {
      const hand = new Toggle({
        html: '<i class="fa-regular fa-hand-pointer"></i>',
        className: 'select',
        title: 'Seleccionar elemento',
        // interaction: new ol.interaction.Select(),
        active: true,
        onToggle: (active) => {
          this.$refs.measures.clear()
          this.activeTool = active ? 'select' : ''
        }
      })

      const ruler = new Toggle({
        html: '<i class="fa-solid fa-ruler"></i>',
        className: 'ruler',
        title: 'Medir distancias',
        // interaction: new ol.interaction.Select(),
        active: false,
        onToggle: (active) => {
          this.$refs.measures.clear()
          this.activeTool = active ? 'measure:distance' : ''
          this.measurementType = active ? 'LineString' : 'Polygon'
        }
      })

      const polygon = new Toggle({
        html: '<i class="fa-solid fa-draw-polygon"></i>',
        className: 'polygon',
        title: 'Medir áreas',
        // interaction: new ol.interaction.Select(),
        active: false,
        onToggle: (active) => {
          this.$refs.measures.clear()
          this.activeTool = active ? 'measure:area' : ''
          this.measurementType = active ? 'Polygon' : 'LineString'
        }
      })

      const mainBar = new ControlBar({ toggleOne: true, group: true })
      mainBar.addControl(hand)
      mainBar.addControl(ruler)
      mainBar.addControl(polygon)
      mainBar.setPosition('top-left')

      this.$refs.map.$map
        .getControls()
        .extend([
          new ScaleLine({ bar: false }),
          new LayerSwitcher(),
          new Rotate({ autoHide: false }),
          mainBar
        ])
    },
    setLayerOptions (vm, options) {
      vm.$layer.setProperties(options)
    },
    updateSize () {
      this.$refs.map.updateSize()
    },
    applyIncidenceStyle (feature) {
      const type = feature.get('data').level
      let color
      switch (type) {
        case 'Grave':
          color = 'red'
          break
        case 'Medio':
          color = 'orange'
          break
        case 'Leve':
          color = 'yellow'
          break
        default:
          color = 'green'
          break
      }

      const icon = new Icon({
        src: `assets/${color}Marker.png`,
        anchor: [0.5, 0.5],
        scale: 0.01 * this.zoom
      })

      return new Style({
        image: icon
      })
    },
    applySelectedStyle (feature) {
      const featureType = feature.get('featureType')

      switch (featureType) {
        case 'Asset':
          return new Style({
            stroke: new Stroke({
              color: 'red',
              width: 2
            }),
            text: new Text({
              textAlign: undefined,
              textBaseline: 'middle',
              font: 'bold 10px/1 verdana',
              text: feature.get('data').key,
              fill: new Fill({ color: 'red' }),
              stroke: new Stroke({ color: '#ffffff', width: 2 }),
              offsetX: 0,
              offsetY: 0,
              placement: 'point',
              maxAngle: 0,
              overflow: true,
              rotation: 0
            })
          })

        case 'Incicence':
          break
      }
    },
    measureStart () {
      this.$refs.measures.clear()
      this.modifyActive = false
      this.measurementTip = 'Click para añadir otro punto a la medición\nDoble click para terminar'
    },
    measureEnd () {
      modifyStyle.setGeometry(tipPoint)
      this.modifyActive = true
      this.$refs.map.$map.once('pointermove', function () {
        modifyStyle.setGeometry()
      })
      this.measurementTip = ''
    },
    applyVectorStyle (feature) {
      return styleFunction(feature, true)
    },
    applyInteractionStyle (feature) {
      return styleFunction(feature, true, this.measurementType, this.measurementTip, this.measures)
    },
    applyModifyStyle () {
      return modifyStyle
    }
  }
}
</script>

<style>
  .ol-layerswitcher .baselayer .layerswitcher-opacity {
    display: none;
  }

  .ol-layerswitcher .baselayer .layerup {
    height: 1.5em;
  }
</style>
