<template>
  <v-row justify="center">
    <v-dialog :value="dialog" @input="$emit('input')" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Iniciar sesión</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Usuario"
                  required
                  v-model="username"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Contraseña"
                  type="password"
                  required
                  v-model="password"
                  @keyup.enter="validate"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <p class="red--text text-center">{{ error }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="validate"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: null
  },
  data: () => ({
    username: '',
    password: '',
    error: null
  }),
  methods: {
    validate: function () {
      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$emit('input')
        })
        .catch((error) => {
          this.error = error.response.data
        })
    }
  }
}
</script>
