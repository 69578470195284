const isDev = process.env.NODE_ENV === 'development'
const wsBaseUrl = isDev ? 'ws://localhost:17700/listen' : 'ws://64.227.65.162:17700/listen'

function listenWebSocket (ws, channel, messageCallback) {
  const url = `${wsBaseUrl}/${channel}`

  closeWebSocket(ws)

  ws = new WebSocket(url)

  ws.onerror = (error) => {
    console.log('WebSocket error: ' + error.message)
  }

  ws.onmessage = messageCallback
}

function closeWebSocket (ws) {
  if (ws != null) {
    ws.close()
    ws = null
  }
}

export { listenWebSocket, closeWebSocket }
