import { apiClient } from './ApiClient'
import { sigcarApiClient } from './RoadPkService'

export default {
  getIncidences (tunnelId) {
    return apiClient.get('/api/incidences/' + tunnelId)
  },
  createIncidence (body) {
    return apiClient.post('/api/incidences', body)
  },
  editIncidence (incidenceId, body) {
    return apiClient.put('/api/incidences/' + incidenceId, body)
  },
  getCoords (params) {
    const tubo1 = params.ubicacion === 'Tubo 1'
    const sentido = tubo1 ? '1' : '2'
    return sigcarApiClient.get('coords?apiKey=c2050450-c7cd-4796-b2df-549a62bad509&carretera=' + params.road + '&pk=' + params.pk + '&sentido=' + sentido + '&srid=4326')
  }
}
