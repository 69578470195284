<template>
  <v-card>
    <v-card-title>
      Eventos SCADA - {{activeTunnel.name}}
    </v-card-title>
    <v-data-table
      :items-per-page="10"
      :headers="headers"
      :items="events"
      :sort-by="['startDate']"
      :sort-desc="true"
      no-data-text='No hay eventos'
      :footer-props="{
        itemsPerPageText: 'Elementos por página:',
        itemsPerPageOptions: [10,20,30,40],
        showFirstLastPage: true,
        firstIcon: 'mdi-skip-previous',
        lastIcon: 'mdi-skip-next',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right'
      }"
    >
      <template v-slot:[`item.startDate`]="{item}">
        <span>{{formatDate(item.startDate)}}</span>
      </template>
      <template v-slot:[`item.endDate`]="{item}">
        <span>{{formatDate(item.endDate)}}</span>
      </template>
      <template v-slot:[`item.editar`]="{item}">
        <v-btn v-if="item.asset" @click="viewAsset(item.asset.key)" small icon>
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'ScadaEventList',
  computed: {
    ...mapState([
      'activeTunnel'
    ])
  },
  props: {
    events: []
  },
  data: () => ({
    headers: [
      { text: 'Inicio', value: 'startDate', width: '15%' },
      { text: 'Fin', value: 'endDate', width: '15%' },
      { text: 'Evento', value: 'type', width: '40%' },
      { text: 'Elemento', value: 'asset.key' },
      { text: '', value: 'editar' }
    ]
  }),
  methods: {
    formatDate (date) {
      const dt = DateTime.fromISO(date)
      return dt.toLocaleString(DateTime.DATETIME_SHORT)
    },
    viewAsset (assetKey) {
      this.$emit('close')
      this.$store.dispatch('selectAsset', assetKey)
    }
  }
}
</script>
