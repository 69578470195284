<template>
  <v-dialog :value="dialog" persistent max-width="450px">
    <v-card class="pa-3">
      <p class="text-center"><v-icon :left="true" color="yellow"> mdi-alert </v-icon>{{warningDialogMsg}}</p>
      <v-row>
        <v-col class="text-center">
          <v-btn @click="$emit('close')" small>Aceptar</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WarningDialog',
  computed: {
    ...mapState(['warningDialogMsg'])
  },
  props: {
    dialog: null
  }
}
</script>
