import Vue from 'vue'
import App from './App.vue'
import VueLayers from 'vuelayers'
import 'vuelayers/dist/vuelayers.min.css' // needs css-loader
import vuetify from './plugins/vuetify'
import store from './store'

Vue.use(VueLayers)

new Vue({
  store,
  vuetify,
  created () {
    const userString = localStorage.getItem('user')
    if (userString) {
      const usuario = JSON.parse(userString)
      this.$store.commit('LOGIN', usuario)
    }
  },
  render: (h) => h(App)
}).$mount('#app')
