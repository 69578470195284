import axios from 'axios'
import store from '../store'
const isDev = process.env.NODE_ENV === 'development'

const apiBase = isDev ? 'http://localhost:3000/' : '/'

const apiClient = axios.create({
  baseURL: apiBase,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      !error.response.request.responseURL.endsWith('auth/login')
    ) {
      store.commit('LOGOUT')
    }
    return Promise.reject(error)
  }
)

const gmaoApiClient = axios.create({
  baseURL: 'http://tugmao.es',
  timeout: 2000,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const scadaApiClient = axios.create({
  baseURL: apiBase + 'scada/',
  timeout: 2000,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export { apiClient, gmaoApiClient, scadaApiClient }
