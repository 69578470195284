<template>
  <div style="width: 100%; height: 100%">
    <NavigationPane
      :currentView="show3d ? '3D' : '2D'"
      @changeViewMode="switch3d(!show3d)"
      @endDrawerEvent="updateMapSize"
      @changedAssetVisibility="changedAssetVisibility"
      @showTunnelInfo="infoDialog = true"
      @showIncidencesTab="showIncidences"
      @showScadaEvents="loadAndShowScadaEvents"
    />

    <AssetInfoPane :currentView="show3d ? '3D' : '2D'" @endDrawerEvent="updateMapSize" @show3d="switch3d"/>

    <MapControl ref="map" :visibleAssets="visibleAssets" />
    <IncidencesTab />

    <v-dialog v-model="showScadaEvents" max-width="1000">
      <scada-event-list :events="scadaEvents" @close="showScadaEvents = false"/>
    </v-dialog>

    <v-dialog v-model="infoDialog" max-width="700">
      <InfoDialog :tunnel="activeTunnel" @close="infoDialog = false" />
    </v-dialog>

    <ifc-viewer ref="ifc" v-show="show3d"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavigationPane from './NavigationPane'
import AssetInfoPane from './AssetInfoPane'
import MapControl from './MapControl'
import InfoDialog from './InfoDialog'
import IncidencesTab from './IncidencesTab'
import IfcViewer from './IfcViewer'
import ScadaEventList from './ScadaEventList'
import TunnelService from '@/services/TunnelService'

export default {
  name: 'App',

  components: {
    NavigationPane,
    AssetInfoPane,
    MapControl,
    InfoDialog,
    IncidencesTab,
    IfcViewer,
    ScadaEventList
  },

  data: () => ({
    infoDialog: false,
    show3d: false,
    modelId: -1,
    expressId: -1,
    visibleAssets: [],
    showScadaEvents: false,
    scadaEvents: []
  }),

  computed: {
    ...mapState(['tunnels', 'activeTunnel', 'tunnelAssets', 'activeAsset'])
  },

  methods: {
    selectTunnel (tunnel) {
      if (!this.activeTunnel || tunnel.id !== this.activeTunnel.id) {
        this.$store.dispatch('selectTunnel', tunnel.id)
      }
    },
    updateMapSize () {
      this.$refs.map.updateSize()
    },
    changedAssetVisibility (visibleAssetIds) {
      this.visibleAssets = visibleAssetIds
    },
    showIncidences () {
      this.$store.dispatch('setIncidencesTab', true)
    },
    switch3d (show, modelId, expressId) {
      this.show3d = show
      this.modelId = modelId
      this.expressId = expressId
    },
    update3d () {
      this.$nextTick(() => {
        this.$refs.ifc.redraw()
        this.$refs.ifc.centerElement(this.modelId, this.expressId)
      })
    },
    loadAndShowScadaEvents () {
      TunnelService.getScadaEvents(this.activeTunnel.id)
        .then(({ data }) => {
          this.scadaEvents = data
          this.showScadaEvents = true
        })
    }
  },

  watch: {
    show3d (visible) {
      if (visible) {
        this.update3d()
      }
    },
    activeAsset (asset) {
      if (!asset) {
        this.switch3d(false, -1, -1)
      } else {
        if (this.show3d) {
          this.modelId = asset.ifcModelId
          this.expressId = asset.ifcExpressId
          this.update3d()
        }
      }
    }
  }
}
</script>
