<template>
   <v-navigation-drawer
      v-model="show"
      app
      clipped
      right
      width="768"
      style="height: calc(100% - 48px); top: 48px"
      mobile-breakpoint="0"
      @transitionend="endDrawerEvent"
    >
      <div v-if="activeAsset">
        <v-data-table
          v-for="propSet in assetPropertySets"
          :key="propSet.id"
          :headers="[...tableHeaders, { text: '', value: 'data-table-expand' }]"
          :items="propSet.children"
          :item-key="'key'"
          :items-per-page="100"
          :expanded.sync="expanded[propSet.id]"
          single-expand
          show-expand
          dense
          class="elevation-3 mt-2 mx-1 asset-info-table"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:top>
            <div class="text-overline pl-2">{{ propSet.value }}</div>
          </template>

          <template v-slot:[`item.dataValue`]="{ item }">
            <span
              :class="{'indigo--text text--darken-2': item.source === 'GMAO', 'green--text text--darken-2': item.source === 'SCADA'}">
              {{ getItemValue(item) }}
            </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="tableHeaders"
                :items="item.children"
                :item-key="'key'"
                dense
                class="elevation-1 my-2 asset-info-table"
                hide-default-header
                hide-default-footer
              >
              <template v-slot:[`item.dataValue`]="{ item }">
                <span
                  :class="{'indigo--text text--darken-2': item.source === 'GMAO', 'green--text text--darken-2': item.source === 'SCADA'}">
                  {{ getItemValue(item) }}
                </span>
              </template>
              </v-data-table>
            </td>
          </template>
          <template
            v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
          >
            <v-icon v-show="item.children" @click="expand(!isExpanded)">
              mdi-chevron-{{ isExpanded ? 'up' : 'down' }}
            </v-icon>
          </template>
        </v-data-table>
        <v-row
          class="my-2"
          justify="space-around"
        >
          <v-btn
            color="primary"
            @click.native="openLink('http://www.tugmao.es/verElemento?id=' + activeAsset.key)"
          >
            GMAO Info.
          </v-btn>
          <v-btn
            color="primary"
            @click.native="openLink('http://www.tugmao.es/verElementoDetallePreventivo?id=' + activeAsset.key)"
          >
            GMAO Mant.
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!ifcLoaded"
            @click.native="$emit('show3d', !show3d, activeAsset.ifcModelId, activeAsset.ifcExpressId)"
          >
            {{show3d ? 'Ocultar 3D' : 'Ver en 3D'}}
          </v-btn>
        </v-row>
      </div>
    </v-navigation-drawer>

</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/AssetService'
import linksMixin from '@/mixins/linksMixin'

export default {
  name: 'AssetInfoPane',
  mixins: [linksMixin],
  props: {
    currentView: {
      type: String,
      required: true
    }
  },
  data: () => ({
    show: false,
    tableHeaders: [
      {
        text: 'Parametro',
        align: 'start',
        value: 'value',
        width: '48%'
      },
      {
        text: 'Valor',
        value: 'dataValue',
        width: '48%'
      }
    ],
    expanded: {},
    assetPropertySets: {},
    ifcValues: [],
    gmaoValues: {},
    scadaValues: {}
  }),

  computed: {
    ...mapState(['dictionaries', 'activeAsset', 'propertySets', 'ifcLoaded']),
    show3d () {
      return this.currentView === '3D'
    }
  },

  watch: {
    async activeAsset (asset) {
      if (!asset) {
        this.show = false
        this.assetPropertySets = {}
        return
      }

      this.ifcValues = asset.assetInfo

      try {
        const { data } = await api.getGmaoInfo(asset.key)
        const result = {}

        for (const key in data) {
          result[`${asset.systemId}_${asset.subsystemId}_${key}`] = data[key]
        }
        this.gmaoValues = result
      } catch (error) {
        console.log(error)
        this.gmaoValues = {}
      }

      try {
        const { data } = await api.getScadaInfo(asset.key)
        const result = {}

        for (const key in data) {
          result[`${asset.systemId}_${asset.subsystemId}_${key}`] = data[key]
        }
        this.scadaValues = result
      } catch (error) {
        console.log(error)
        this.scadaValues = {}
      }

      /*
      const pSets = assetType.propertySets

      const pSetTree = pSets.filter(x => x.parentId == null).map(x => ({ ...x, children: [...x.properties] }))

      for (let i = 0; i < pSetTree.length; i++) {
        pSetTree[i].children.push(...pSets.filter(x => x.parentId === pSetTree[i].id).map(x => ({ ...x, children: [...x.properties] })))
      }
      */
      this.assetPropertySets = this.propertySets[asset.assetTypeId]
      this.show = !!asset
    }
  },

  methods: {
    endDrawerEvent () {
      this.$emit('endDrawerEvent')
    },
    getLabel (key) {
      const labels = this.dictionaries.labels
      const result = labels.find(x => x.id === key)

      return result ? result.value : ''
    },
    getItemValue (item) {
      let value = ''
      switch (item.source) {
        case 'IFC': {
          const ifcValue = this.ifcValues.find(x => x.propertyId === item.id)
          if (ifcValue == null) {
            return '\u{274C} IFC: Dato no disponible \u{274C}'
          }

          value = ifcValue.value
          break
        }
        case 'GMAO': {
          if (this.gmaoValues == null || !Object.prototype.hasOwnProperty.call(this.gmaoValues, item.key)) {
            return '\u{274C} GMAO: Dato no disponible \u{274C}'
          }

          value = this.gmaoValues[item.key]
          break
        }
        case 'SCADA': {
          if (this.scadaValues == null || !Object.prototype.hasOwnProperty.call(this.scadaValues, item.key)) {
            return '\u{274C} SCADA: Dato no disponible \u{274C}'
          }

          value = this.scadaValues[item.key]
          break
        }
        default:
          return ''
      }

      if (item.type === 'Boolean') {
        return value ? '\u{2705} (' + item.source + ')' : '\u{274C} (' + item.source + ')'
      }

      if (item.type === 'NegatedBoolean') {
        return value ? '\u{274C} (' + item.source + ')' : '\u{2705} (' + item.source + ')'
      }

      return value
    }
  }
}
</script>>

<style>
.asset-info-table td {
  width: 50%;
}
</style>
