<template>
    <v-navigation-drawer
      app clipped v-model="show"
      width="288"
      mobile-breakpoint="0"
      @transitionend="endDrawerEvent">
      <v-subheader>Inventario</v-subheader>

      <v-expansion-panels v-model="expandedPanel">
        <v-expansion-panel v-for="cat in dictionaries.systems" :key="cat.id" >
          <v-expansion-panel-header class="pa-2">
            {{ cat.value }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0" v-if="classifiedTunnelAssets.hasOwnProperty(cat.id)">
            <v-list
              dense
              v-for="subcat in cat.children"
              :key="subcat.id"
              class="pa-0"
            >
              <v-subheader>
                {{ subcat.value }}
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                      <v-checkbox
                        v-bind="attrs"
                        dense
                        class="align-center mt-4 pl-2"
                        :disabled="classifiedTunnelAssets[cat.id][subcat.id].length === 0"
                        @change="changeGroupVisibility($event, cat.id, subcat.id)"
                      ></v-checkbox>
                    </div>
                  </template>
                  <span>Mostrar/ocultar todos</span>
                </v-tooltip>
              </v-subheader>
              <v-list-item-group
                color="primary"
                v-model="selectedAsset"
                @change="changeSelection($event, cat.id, subcat.id)"
              >
                <v-list-item
                  v-for="asset in classifiedTunnelAssets[cat.id][subcat.id]"
                  :key="asset.id"
                  :value="asset.key"
                  class="px-1 list-item-low-height"
                >
                  <v-list-item-action class="mr-2">
                    <v-checkbox
                      v-model="visibleAssets"
                      :value="asset.id"
                      @click.stop=""
                      @change="itemVisibilityChanged"
                    ></v-checkbox
                  ></v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="asset.key" class="nav-item-small-font"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-divider></v-divider>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <template v-slot:append>
        <TunnelLinks
          v-if="activeTunnel"
          :links="links"
          :currentView="currentView"
          @showTunnelInfo="$emit('showTunnelInfo')"
          @showIncidencesTab="$emit('showIncidencesTab')"
          @showScadaEvents="$emit('showScadaEvents')"
          @changeViewMode="$emit('changeViewMode')"
          @downloadIfc="downloadIfc()"
        />
      </template>
    </v-navigation-drawer>
</template>

<script>
import linksMixin from '@/mixins/linksMixin'
import { mapState } from 'vuex'
import TunnelLinks from '@/components/TunnelLinks.vue'
import { downloadZip } from 'client-zip'

export default {
  name: 'NavigationPane',
  mixins: [linksMixin],
  props: {
    currentView: {
      type: String,
      required: true
    }
  },

  components: {
    TunnelLinks
  },

  data: () => ({
    show: false,
    classifiedTunnelAssets: {},
    visibleAssets: [],
    selectedAsset: null,
    expandedPanel: null,
    panels: {},
    links: []
  }),
  computed: {
    ...mapState(['dictionaries', 'activeTunnel', 'tunnelAssets', 'activeAsset'])
  },
  watch: {
    activeTunnel (tunnel, oldTunnel) {
      this.show = !!tunnel

      if (tunnel !== oldTunnel) {
        this.classifiedTunnelAssets = {}
        this.visibleAssets = []
      }

      if (this.show) {
        this.links = tunnel.links.filter(x => x.typeId === 'LINK')
      }
    },
    tunnelAssets (assets) {
      const result = {}
      for (let i = 0; i < this.dictionaries.systems.length; i++) {
        const system = this.dictionaries.systems[i]
        result[system.id] = {}
        this.panels[system.id] = i

        for (let j = 0; j < system.children.length; j++) {
          const subsystem = system.children[j]
          result[system.id][subsystem.id] = assets.filter(x => x.systemId === system.id && x.subsystemId === subsystem.id)
        }
      }

      this.classifiedTunnelAssets = result
    },
    activeAsset (asset, oldAsset) {
      if (!asset) {
        this.selectedAsset = null
        return
      }

      if (asset.key === this.selectedAsset) {
        return
      }

      this.selectedAsset = asset.key
      this.expandedPanel = this.panels[asset.systemId]
    }
  },

  methods: {
    endDrawerEvent () {
      this.$emit('endDrawerEvent')
    },
    changeGroupVisibility (visible, systemId, subsystemId) {
      const assets = this.classifiedTunnelAssets[systemId][subsystemId]
      for (let i = 0; i < assets.length; i++) {
        const assetId = assets[i].id
        const index = this.visibleAssets.indexOf(assetId)

        if (visible && index === -1) {
          this.visibleAssets.push(assetId)
        }

        if (!visible && index > -1) {
          this.visibleAssets.splice(index, 1)
        }
      }

      this.$emit('changedAssetVisibility', this.visibleAssets)
    },
    itemVisibilityChanged () {
      this.$emit('changedAssetVisibility', this.visibleAssets)
    },
    changeSelection (assetKey) {
      if (!assetKey) {
        this.selectedAsset = null
        this.$store.dispatch('selectAsset', null)
      } else {
        this.selectedAsset = assetKey
        this.$store.dispatch('selectAsset', assetKey)
      }
    },
    async downloadIfc () {
      if (!this.activeTunnel) {
        return
      }

      try {
        const ifcs = [await fetch(this.activeTunnel.ifcBase), await fetch(this.activeTunnel.ifcBase2)]

        for (let i = 0; i < this.activeTunnel.ifc.length; i++) {
          ifcs.push(await fetch(this.activeTunnel.ifc[i]))
        }

        const blob = await downloadZip(ifcs).blob()

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = this.activeTunnel.name + '-ifcs.zip'
        link.click()

        link.remove()
        URL.revokeObjectURL(link.href)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.nav-item-small-font {
  font-size: 0.75rem !important;
}

.list-item-low-height {
  height: 24px !important;
}
</style>
