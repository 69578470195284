import { apiClient } from './ApiClient'

export default {
  getDictionary (id) {
    return apiClient.get('/api/' + id)
  },
  getPropertySets () {
    return apiClient.get('/api/propertySets')
  }
}
