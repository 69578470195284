<template>
    <v-dialog
      v-model="incidencesTab"
      max-width="1000px"
      @click:outside="cerrarTabla"
      v-if="activeTunnel"
    >
      <v-card max-height="650px" class="text-center pb-3">
        <v-card-title class="justify-center">
          <span>Incidencias - {{activeTunnel.name}}</span>
        </v-card-title>
        <v-data-table
          dense
          :items-per-page="10"
          :headers="headers"
          :items="incidences"
          :sort-by="['updatedAt']"
          :sort-desc="true"
          multi-sort
          no-data-text='No hay incidencias'
          :footer-props="{
            itemsPerPageText: 'Elementos por página:',
            itemsPerPageOptions: [10,20,30,40],
            showFirstLastPage: true,
            firstIcon: 'mdi-skip-previous',
            lastIcon: 'mdi-skip-next',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
          }"
          show-expand
          single-expand
        >
          <template v-if="editableRoles.includes(user.role)" v-slot:[`item.editar`]="{item}">
            <v-btn v-if="user.role === 'conserv' && item.state === 'Resuelta'" disabled small icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-else @click="editar(item)" small icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.date`]="{item}">
            <span>{{fechaFormateada(item.date)}}</span>
          </template>
          <template v-slot:[`item.updatedAt`]="{item}">
            <span>{{fechaFormateada(item.updatedAt)}}</span>
          </template>
          <template v-slot:[`item.state`]="{item}">
            <span v-if="item.state === 'Abierta'" id="Abierta">{{item.state}}</span>
            <span v-if="item.state === 'En pausa'" id="Pausa">{{item.state}}</span>
            <span v-if="item.state === 'Resuelta'" id="Resuelta">{{item.state}}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <thead>
                <tr>
                  <th class="text-subtitle-1">Carretera</th>
                  <th class="text-subtitle-1">PK</th>
                  <th class="text-subtitle-1">Actores externos</th>
                  <th class="text-subtitle-1">Daños</th>
                  <th class="text-subtitle-1">Afecciones</th>
                  <th class="text-subtitle-1">Resolución</th>
                </tr>
              </thead>
              <tbody>
                <td>{{ item.road }}</td>
                <td>{{ item.pk }}</td>
                <td v-if="item.external_actors">{{ item.external_actors.join(', ') }}</td>
                <td v-else></td>
                <td>{{ item.external_damage }}</td>
                <td v-if="item.internal_damage">{{ item.internal_damage.join(', ') }}</td>
                <td v-else></td>
                <td>{{ item.resolution }}</td>
              </tbody>
            </td>
          </template>
        </v-data-table>
        <v-btn v-if="user.role === 'admin' || user.role === 'conserv'" color="success" small @click="añadir()">Añadir</v-btn>
      </v-card>
      <incidences-form :dialog="showIncidencesForm" @close="showIncidencesForm = false" />
      <incidences-state-form :dialog="showIncidencesStateForm" @close="showIncidencesStateForm = false" />
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import IncidencesForm from './IncidencesForm.vue'
import IncidencesStateForm from './IncidencesStateForm.vue'

export default {
  components: { IncidencesForm, IncidencesStateForm },
  name: 'IncidencesTab',
  computed: {
    ...mapState([
      'incidencesTab',
      'activeTunnel',
      'incidences',
      'user'
    ])
  },
  data: () => ({
    headers: [
      { text: 'Tipo', value: 'type', width: 100, class: 'text-subtitle-1' },
      { text: 'Fecha', value: 'date', width: 150, class: 'text-subtitle-1' },
      { text: 'Ubicación', value: 'location', width: 150, class: 'text-subtitle-1' },
      { text: 'Causa', value: 'reason', width: 150, class: 'text-subtitle-1' },
      { text: 'Última modific.', value: 'updatedAt', width: 180, class: 'text-subtitle-1' },
      { text: 'Estado', value: 'state', width: 120, class: 'text-subtitle-1' },
      { text: '', value: 'editar', width: 50 },
      { text: '', value: 'data-table-expand', width: 50 }
    ],
    editableRoles: ['admin', 'conserv', 'rst'],
    showIncidencesForm: false,
    showIncidencesStateForm: false
  }),
  methods: {
    cerrarTabla () {
      this.$store.dispatch('setIncidencesTab', false)
    },
    editar (incidenceSelected) {
      this.$store.dispatch('setIncidenceSelected', incidenceSelected)
      if (this.user.role === 'admin' || this.user.role === 'conserv') {
        this.showIncidencesForm = true
      } else {
        this.showIncidencesStateForm = true
      }
    },
    añadir () {
      this.showIncidencesForm = true
    },
    fechaFormateada (fecha) {
      const [year, month, day] = fecha.split('-')
      return `${day.slice(0, 2)}-${month}-${year}`
    }
  }
}
</script>

<style scoped>
  #Abierta {
    color: orange;
  }
  #Pausa {
    color: blue;
  }
  #Resuelta {
    color: green;
  }
  th, td {
    padding-left: 4vw;
  }
</style>
