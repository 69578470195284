import Vue from 'vue'
import Vuex from 'vuex'
import { apiClient } from '@/services/ApiClient'
import AuthService from '@/services/AuthService'
import TunnelService from '@/services/TunnelService.js'
import AssetService from '@/services/AssetService.js'
import DictionariesService from '@/services/DictionariesService'
import IncidenceService from '@/services/IncidenceService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    dictionaries: {},
    tunnels: null,
    activeTunnel: null,
    tunnelAssets: null,
    activeAsset: null,
    incidencesTab: null,
    incidences: null,
    incidenceSelected: null,
    provinces: [],
    propertySets: {},
    ifcLoaded: false,
    warningDialogMsg: null,
    roles: [
      { text: 'Administrador', value: 'admin' },
      { text: 'Subdirección General de Conservación', value: 'sgc' },
      { text: 'Jefe de Demarcación', value: 'jdem' },
      { text: 'Jefe de Unidad', value: 'juni' },
      { text: 'Director de Explotación', value: 'dexplo' },
      { text: 'Responsable de Seguridad del Túnel', value: 'rst' },
      { text: 'Conservadora', value: 'conserv' },
      { text: 'Organismo de Inspección', value: 'oi' },
      { text: 'Sólo consulta', value: 'consulta' }
    ]
  },
  mutations: {
    LOGIN (state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
      apiClient.defaults.headers.common.Authorization = `Bearer ${user.token}`
      this.dispatch('fetchPropertySets')
      this.dispatch('fetchDictionary', 'systems')
      this.dispatch('fetchProvinces')
      this.dispatch('fetchTunnels')
    },
    LOGOUT () {
      localStorage.removeItem('user')
      location.reload()
    },
    SET_DICTIONARY (state, payload) {
      state.dictionaries[payload.key] = payload.data
    },
    SET_TUNNELS (state, tunnels) {
      state.tunnels = tunnels
    },
    SET_ACTIVE_TUNNEL (state, tunnel) {
      state.activeTunnel = tunnel
    },
    SET_ASSETS (state, assets) {
      state.tunnelAssets = assets
    },
    SET_ACTIVE_ASSET (state, asset) {
      state.activeAsset = asset
    },
    SET_INCIDENCES_TAB (state, incidencesTab) {
      state.incidencesTab = incidencesTab
    },
    SET_INCIDENCES (state, incidences) {
      state.incidences = incidences
      if (incidences.filter(i => i.state === 'Abierta').length > 0 && state.user.role === 'rst') {
        state.incidenceDialog = true
      }
    },
    SET_INCIDENCE_SELECTED (state, incidenceSelected) {
      state.incidenceSelected = incidenceSelected
    },
    SET_PROVINCES (state, provinces) {
      state.provinces = provinces
    },
    SET_PROPERTY_SETS (state, pSets) {
      state.propertySets = pSets
    },
    SET_IFC_LOADED (state, value) {
      state.ifcLoaded = value
    },
    SET_WARNING_DIALOG_MSG (state, warningDialogMsg) {
      state.warningDialogMsg = warningDialogMsg
    },
    UPDATE_TUNNEL_IR (state, data) {
      const tunnel = state.tunnels.find(x => x.id === data.tunnelId)

      if (!tunnel) {
        return
      }

      tunnel.ir = data.ir

      if (tunnel.id === state.activeTunnel.id) {
        state.activeTunnel.ir = data.ir
      }
    }
  },
  actions: {
    async login ({ commit }, credentials) {
      const { data } = await AuthService.login(credentials)
      commit('LOGIN', data)
    },
    logout ({ commit }) {
      commit('LOGOUT')
    },
    fetchDictionary ({ commit }, dictionaryId) {
      DictionariesService.getDictionary(dictionaryId)
        .then((response) => {
          commit('SET_DICTIONARY', { key: dictionaryId, data: response.data })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchProvinces ({ commit }) {
      DictionariesService.getDictionary('provinces')
        .then((response) => {
          commit('SET_PROVINCES', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchPropertySets ({ commit }) {
      DictionariesService.getPropertySets()
        .then((response) => {
          const assetTypes = response.data
          const result = {}
          for (let i = 0; i < assetTypes.length; i++) {
            const pSets = assetTypes[i].propertySets

            const pSetTree = pSets
              .filter(x => x.parentId == null)
              .map(x => {
                const { properties, ...values } = x
                return { ...values, children: [...properties] }
              })

            for (let i = 0; i < pSetTree.length; i++) {
              const childPropertySets = pSets
                .filter(x => x.parentId === pSetTree[i].id)
                .map(x => {
                  const { properties, ...values } = x
                  return { ...values, children: [...properties] }
                })

              pSetTree[i].children.push(...childPropertySets)
            }

            result[assetTypes[i].id] = pSetTree
          }

          commit('SET_PROPERTY_SETS', result)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchTunnels ({ commit }) {
      TunnelService.getTunnels()
        .then((response) => {
          commit('SET_TUNNELS', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchAssets ({ commit }, tunnelId) {
      AssetService.getAssets(tunnelId)
        .then((response) => {
          commit('SET_ASSETS', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    selectTunnel ({ commit, dispatch }, id) {
      if (!id) {
        commit('SET_ACTIVE_TUNNEL', null)
        commit('SET_ACTIVE_ASSET', null)
        commit('SET_ASSETS', [])
        return
      }

      TunnelService.getTunnel(id)
        .then((response) => {
          commit('SET_ACTIVE_TUNNEL', response.data)
          commit('SET_ACTIVE_ASSET', null)
          dispatch('fetchAssets', id)
          IncidenceService.getIncidences(id)
            .then(({ data }) => {
              commit('SET_INCIDENCES', data)
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    selectAsset ({ commit }, assetKey) {
      if (assetKey != null) {
        AssetService.getAsset(assetKey)
          .then((response) => {
            commit('SET_ACTIVE_ASSET', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        commit('SET_ACTIVE_ASSET', null)
      }
    },
    selectAssetIfc ({ commit, state }, data) {
      if (data != null && state.activeTunnel != null) {
        const { modelId, expressId } = data
        AssetService.getAssetIfc(state.activeTunnel.id, modelId, expressId)
          .then((response) => {
            commit('SET_ACTIVE_ASSET', response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        commit('SET_ACTIVE_ASSET', null)
      }
    },
    setIncidencesTab ({ commit }, incidencesTab) {
      commit('SET_INCIDENCES_TAB', incidencesTab)
    },
    setIncidenceSelected ({ commit }, incidenceSelected) {
      commit('SET_INCIDENCE_SELECTED', incidenceSelected)
    },
    setIfcLoaded ({ commit }, value) {
      commit('SET_IFC_LOADED', value)
    },
    setWarningDialogMsg ({ commit }, warningDialogMsg) {
      commit('SET_WARNING_DIALOG_MSG', warningDialogMsg)
    },
    updateTunnelIR ({ commit }, data) {
      commit('UPDATE_TUNNEL_IR', data)
    }
  },
  getters: {
    loggedIn (state) {
      return !!state.user
    }
  },
  modules: {}
})
