import { apiClient } from './ApiClient'

export default {
  getTunnels () {
    return apiClient.get('/api/tunnels')
  },
  getTunnel (id) {
    return apiClient.get('/api/tunnels/' + id)
  },
  getScadaEvents (id) {
    return apiClient.get('/api/tunnels/' + id + '/scadaEvents')
  }
}
