<template>
  <v-app>
    <v-app-bar app dense clipped-left clipped-right color="primary" dark>
      <v-toolbar-title v-text="title" />
      <v-spacer></v-spacer>
      <v-autocomplete
        class="mr-2"
        v-model="selectedProvince"
        dense
        solo
        :items="[{id: 0, name: 'TODAS'}, ...provinces]"
        item-text="name"
        item-value="id"
        clearable
        dark
        background-color="#6aaaff"
        placeholder="Selecciona una provincia"
        hide-details
      >
      </v-autocomplete>
      <v-autocomplete
        v-model="selectedTunnel"
        dense
        solo
        :items="provinceTunnels"
        :item-text="getItemText"
        item-value="id"
        clearable
        dark
        background-color="#6aaaff"
        placeholder="Selecciona un túnel"
        hide-details
        no-data-text="Sin provincia seleccionada o no hay túneles en ella"
      >
        <template v-slot:selection="data">
          {{ data.item.name }}
        </template>
      </v-autocomplete>
      <v-chip
        v-if="activeTunnel"
        class="ma-2"
        :color="irBackgroundColor(activeTunnel.ir)"
        :text-color="irTextColor(activeTunnel.ir)"
      >
        IR: {{activeTunnel.ir}}
      </v-chip>
      <v-spacer></v-spacer>
      <span v-if="loggedIn">
        <span>{{ user.login }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="showChat = true">
              <v-icon>mdi-forum</v-icon>
            </v-btn>
          </template>
          <span>Comunicaciones</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="user.role === 'admin'" icon v-bind="attrs" v-on="on" @click="showUserManagement = true">
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-btn>
          </template>
          <span>Gestión de usuarios</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="logout">
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>Cerrar sesión</span>
        </v-tooltip>
      </span>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(link, index) in links"
            :key="index"
            @click="selectLink(link)"
          >
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <MainPage />
      <LoginDialog :dialog="!loggedIn" />
      <ChatDialog :dialog="showChat" @close="showChat = false"/>
      <UsersDialog :dialog="showUserManagement" @close="showUserManagement = false"/>
      <IncidenceDialog :dialog="showIncidenceDialog" @close="showIncidenceDialog = false" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/store/helpers'
import MainPage from '@/components/MainPage.vue'
import LoginDialog from '@/components/LoginDialog.vue'
import ChatDialog from '@/components/ChatDialog.vue'
import UsersDialog from '@/components/UsersDialog.vue'
import linksMixin from '@/mixins/linksMixin'
import IncidenceDialog from '@/components/IncidenceDialog.vue'

import { listenWebSocket, closeWebSocket } from '@/utils/websockets'

export default {
  name: 'App',

  components: {
    MainPage,
    LoginDialog,
    ChatDialog,
    UsersDialog,
    IncidenceDialog
  },

  mixins: [linksMixin],

  data: () => ({
    title: 'BIMEXTUNEL',
    selectedProvince: '',
    selectedTunnel: '',
    provinceTunnels: [],
    links: [
      {
        title: 'BIT 2',
        url: 'https://www.bit2.es/'
      },
      {
        title: 'Gestor Documental',
        url: 'http://sgd.portal-alauda.com'
      }
    ],
    showChat: false,
    showUserManagement: false,
    showIncidenceDialog: false,
    irWebSocket: null,
    incidencesWebSocket: null
  }),

  computed: {
    ...mapState(['user', 'tunnels', 'activeTunnel', 'provinces', 'incidences']),
    ...authComputed
  },

  watch: {
    selectedProvince () {
      this.refreshTunnelList()
    },
    selectedTunnel (newValue) {
      if (!this.activeTunnel || newValue !== this.activeTunnel.id) {
        this.$store.dispatch('selectTunnel', newValue)

        const callback = (m) => {
          const data = JSON.parse(m.data)
          const index = this.incidences.findIndex((i) => i.id === data.id)

          if (index !== -1) {
            this.incidences.splice(index, 1)
          }

          this.incidences.push(data)
        }

        listenWebSocket(this.incidencesWebSocket, `tunnel_${newValue}`, callback)
      }
    },
    incidences (newValue, oldValue) {
      if (newValue) {
        oldValue = oldValue != null ? oldValue : []
        if (newValue.length !== oldValue.length) {
          if (this.incidences.filter(i => i.state === 'Abierta').length > 0 && this.user.role === 'rst') {
            this.showIncidenceDialog = true
          }
        }
      }
    }
  },

  methods: {
    selectLink (link) {
      this.openLink(link.url)
    },
    logout () {
      this.$store.dispatch('logout')
    },
    getItemText (item) {
      return `${item.name} (IR: ${item.ir})`
    },
    irBackgroundColor (value) {
      const r = Math.min(1, 2 * (value - 1)) * 255
      const g = Math.min(1, 2 * (2 - value)) * 255
      const b = 0
      const a = 1
      return `rgba(${r}, ${g}, ${b}, ${a})`
    },
    irTextColor (value) {
      const r = Math.min(1, 2 * (value - 1)) * 255
      const g = Math.min(1, 2 * (2 - value)) * 255

      function getsRGB (color) {
        const normalizedColor = color / 255
        return normalizedColor <= 0.03928 ? normalizedColor / 12.92 : Math.pow((normalizedColor + 0.055) / 1.055, 2.4)
      }

      function getLuminance (r, g, b) {
        return (0.2126 * getsRGB(r)) + (0.7152 * getsRGB(g)) + (0.0722 * getsRGB(b))
      }

      function getContrast ({ r: r1, g: g1, b: b1 }, { r: r2, g: g2, b: b2 }) {
        const l1 = getLuminance(r1, g1, b1)
        const l2 = getLuminance(r2, g2, b2)
        return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05)
      }

      const bgColor = { r, g, b: 0 }
      const whiteContrast = getContrast(bgColor, { r: 255, g: 255, b: 255 })
      const blackContrast = getContrast(bgColor, { r: 0, g: 0, b: 0 })

      return whiteContrast + 5 > blackContrast - 5 ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)'
    },
    refreshTunnelList () {
      if (this.selectedProvince === 0) {
        this.provinceTunnels = this.tunnels
      } else {
        this.provinceTunnels = this.tunnels.filter(x => x.provinceId === this.selectedProvince)
      }
    }
  },
  mounted: function () {
    const callback = (e) => {
      const data = JSON.parse(e.data)
      this.$store.dispatch('updateTunnelIR', data)
    }
    listenWebSocket(this.irWebSocket, 'tunnel_ir', callback)
  },
  beforeDestroy: function () {
    closeWebSocket(this.irWebSocket)
  }
}
</script>

<style>
html, body {
  overflow: hidden !important;
}

.v-expansion-panel-content__wrap {
  padding: 4px;
}
</style>
